import { environmentConfig } from "@src/appV2/environment";
import { openBrowser } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { Callout } from "@src/appV2/ShiftDiscovery/components/Callout";
import { type Worker } from "@src/appV2/Worker/api/types";

import { type PlacementCandidate } from "../types";

interface IntroductionVideoCalloutProps {
  placementCandidate: PlacementCandidate;
  worker: Worker;
}

export function IntroductionVideoCallout(props: IntroductionVideoCalloutProps) {
  const { placementCandidate, worker } = props;

  if (placementCandidate.introductionUrl) {
    return null;
  }

  return (
    <Callout
      title="Upload an intro video"
      text="Complete your profile"
      iconType="video-outlined"
      sx={(theme) => ({
        backgroundColor: theme.palette.callout?.success.labelDark,
        color: theme.palette.callout?.success.text,
      })}
      onClick={() => {
        openBrowser(
          `${environmentConfig.REACT_APP_VIDEOASK_UPLOAD_URL}#worker_id=${worker.userId}&contact_name=${worker.name}&contact_email=${worker.email}`
        );
        logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_VIDEOASK_VIA_BROWSER_CLICKED, {
          placementCandidateId: placementCandidate.id,
          source: "placements_page_callout",
        });
      }}
    />
  );
}

import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { type Worker } from "@src/appV2/Worker/api/types";
import { isEmpty } from "lodash";

import { APP_V2_APP_EVENTS, useToast } from "../../lib";
import { logEvent } from "../../lib/analytics";
import { useCreateOrGetPlacementCandidate } from "../api/useCreateOrGetPlacementCandidate";
import { useUpdatePlacementCandidate } from "../api/useUpdatePlacementCandidate";
import { type PlacementCandidate, PlacementCandidateStatus } from "../types";
import { JobPreferenceForm } from "./JobPreferenceForm";
import { JobsIntroductionPage } from "./JobsIntroductionPage";
import { OnboardingPlacementCandidate } from "./OnboardingPlacementCandidate";
import { PendingPlacementCandidate } from "./PendingPlacementCandidate";
import { UploadCandidateResume } from "./UploadCandidateResume";
import { UploadedCandidateRequirements } from "./UploadedCandidateRequirements";

interface PlacementCandidateOnboardingContainerProps {
  placementCandidate: PlacementCandidate;
  worker: Worker;
}

export function PlacementCandidateOnboardingContainer(
  props: PlacementCandidateOnboardingContainerProps
) {
  const { placementCandidate, worker } = props;
  const { showSuccessToast } = useToast();
  const { refetch: refetchPlacementCandidate } = useCreateOrGetPlacementCandidate(
    { workerId: worker.userId },
    { enabled: isDefined(worker.userId) }
  );
  useLogEffect(
    APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_PAGE_VIEWED,
    {
      placementCandidateId: placementCandidate?.id,
    },
    {
      enabled: isDefined(placementCandidate) && isDefined(placementCandidate.id),
    }
  );
  const { mutateAsync: updatePlacementCandidate } = useUpdatePlacementCandidate();
  const hasIntroductionUrl =
    isDefined(placementCandidate.introductionUrl) && !isEmpty(placementCandidate.introductionUrl);

  return (
    <>
      <PullToRefresh onRefresh={refetchPlacementCandidate} />
      <Stack sx={{ overflowY: "auto", p: 2, pb: 15 }} flex={1} spacing={2}>
        <Stack flex={1}>
          {placementCandidate.status === PlacementCandidateStatus.DISABLED && (
            <JobsIntroductionPage
              placementCandidateId={placementCandidate.id}
              workerId={worker.userId}
            />
          )}

          {placementCandidate.status !== PlacementCandidateStatus.DISABLED && (
            <>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  {placementCandidate.status !== PlacementCandidateStatus.ACTIVATED && (
                    <Text>
                      Upload your resume and select your preferences to activate your profile
                    </Text>
                  )}
                  <UploadCandidateResume
                    placementCandidate={placementCandidate}
                    onResumeUploaded={async () => {
                      // This is a temporary hack to ensure that the placement candidate is activated when resume and job preferences are present.
                      // The resume upload is being done directly via s3 and we do not have a way to update status on resume upload.
                      if (
                        isDefined(placementCandidate.jobTypesInterested) &&
                        placementCandidate.jobTypesInterested.length > 0 &&
                        isDefined(placementCandidate.shiftTypesInterested) &&
                        placementCandidate.shiftTypesInterested.length > 0
                      ) {
                        await updatePlacementCandidate({
                          placementCandidateId: placementCandidate.id,
                          workerId: worker.userId,
                          status: PlacementCandidateStatus.ACTIVATED,
                        });
                      }

                      await refetchPlacementCandidate();
                      showSuccessToast("Resume uploaded successfully");
                    }}
                  />

                  <JobPreferenceForm
                    initialJobTypes={placementCandidate.jobTypesInterested}
                    initialShiftTypes={placementCandidate.shiftTypesInterested}
                    initialAddressDetails={placementCandidate.preferredAreas?.[0]}
                    worker={worker}
                    onSave={async (data) => {
                      logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_JOB_PREFERENCES_SUBMITTED, {
                        placementCandidateId: placementCandidate.id,
                        status: placementCandidate.status,
                        jobTypes: data.jobTypes,
                        shiftTypes: data.shiftTypes,
                        preferredAreas: data.addressObject,
                      });
                      await updatePlacementCandidate({
                        placementCandidateId: placementCandidate.id,
                        workerId: worker.userId,
                        jobTypesInterested: data.jobTypes,
                        shiftTypesInterested: data.shiftTypes,
                        preferredAreas: data.addressObject ? [data.addressObject] : undefined,
                      });
                      showSuccessToast("Your job preferences have been updated.");
                      await refetchPlacementCandidate();
                    }}
                  />
                </Stack>

                {!hasIntroductionUrl &&
                  ((isDefined(placementCandidate.jobTypesInterested) &&
                    placementCandidate.jobTypesInterested.length > 0) ||
                    (isDefined(placementCandidate.shiftTypesInterested) &&
                      placementCandidate.shiftTypesInterested.length > 0)) && (
                    <OnboardingPlacementCandidate
                      placementCandidateId={placementCandidate.id}
                      worker={worker}
                    />
                  )}
              </Stack>
              {hasIntroductionUrl && (
                <UploadedCandidateRequirements
                  placementCandidateId={placementCandidate.id}
                  introductionUrl={placementCandidate.introductionUrl}
                  worker={worker}
                />
              )}
            </>
          )}
        </Stack>

        {placementCandidate.status === PlacementCandidateStatus.PENDING && (
          <PendingPlacementCandidate />
        )}
      </Stack>
    </>
  );
}

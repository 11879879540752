import { isDefined } from "@clipboard-health/util-ts";
import { z } from "zod";

import { JobType, placementGeoLocation, ShiftNameType } from "../types";

export const jobPreferenceSchema = z.object({
  jobTypes: z.array(z.nativeEnum(JobType)).min(1, "Please select at least one job type"),
  shiftTypes: z.array(z.nativeEnum(ShiftNameType)).min(1, "Please select at least one shift type"),
  addressObject: z
    .object(
      {
        address: z.object(
          {
            streetNumber: z.string().optional(),
            streetName: z.string().optional(),
            city: z.string().optional(),
            region: z.string().optional(),
            state: z.string().optional(),
            stateCode: z.string().optional(),
            country: z.string().optional(),
            countryCode: z.string().optional(),
            postalCode: z.string().optional(),
            formatted: z.string().optional(),
          },
          {
            required_error: "Please enter a preferred location address",
            invalid_type_error: "Please enter a valid address",
          }
        ),
        location: placementGeoLocation,
        distance: z.number(),
      },

      {
        required_error: "Please enter a preferred location address",
        invalid_type_error: "Please enter a valid address",
      }
    )

    .refine(
      (data) => {
        const keysToCheck = [
          "city",
          "country",
          "countryCode",
          "formatted",
          "postalCode",
          "state",
          "stateCode",
        ];

        return !keysToCheck.some(
          (key) => !isDefined(data.address[key as keyof typeof data.address])
        );
      },
      {
        message: "Please enter a valid workplace address with city and postal code",
      }
    ),
});

export type JobPreferenceFormData = z.infer<typeof jobPreferenceSchema>;

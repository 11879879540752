import { Text } from "@clipboard-health/ui-react";
import { Box, Stack } from "@mui/material";
import { CbhIcon, type IconType } from "@src/appV2/ShiftDiscovery/components/CbhIcon";

interface JobsFeatureListItemProps {
  icon: IconType;
  text: string;
}

export function JobsFeatureListItem(props: JobsFeatureListItemProps) {
  const { icon, text } = props;

  return (
    <Stack direction="row" spacing={4} alignItems="center" justifyContent="flex-start">
      <Box flexShrink={0} sx={{ margin: "auto" }}>
        <CbhIcon type={icon} size="medium" />
      </Box>
      <Text variant="h5">{text}</Text>
    </Stack>
  );
}
